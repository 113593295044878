import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import Loader from '../../components/Loader';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  animate: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="app__header app__flex">
      {loading ? (
        <Loader />
      ) : (
        <>
          <motion.div
            animate={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="app__header-info"
          >
            <div className="app__header-badge">
              <div className="badge-cmp app__flex">
                <span>👋</span>
                <div style={{ marginLeft: 20 }}>
                  <p className="p-text">Hello, I am</p>
                  <h1 className="head-text">Darko</h1>
                </div>
              </div>

              <div className="tag-cmp app__flex">
                <p className="p-text">Full Stack Dev</p>
                <p className="p-text">UI UX Designer</p>
              </div>
            </div>
          </motion.div>

          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, delayChildren: 0.5 }}
            className="app__header-img"
          >
            <img src={images.profile} alt="profile_bg" />
            <motion.img
              animate={{ scale: [0, 1] }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              src={images.circle}
              alt="profile_circle"
              className="overlay_circle"
            />
          </motion.div>

          <motion.div
            variants={scaleVariants}
            animate={scaleVariants.animate}
            className="app__header-circles"
          >
            {[images.react, images.redux, images.sass].map((circle, index) => (
              <div className="circle-cmp app__flex" key={`circle-${index}`}>
                <img src={circle} alt="profile_bg" />
              </div>
            ))}
          </motion.div>
        </>
      )}
    </div>
  );
};

export default AppWrap(Header, 'home');
