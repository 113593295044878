import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";

const Skills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "skills"]';
    client.fetch(query).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="title">Technical Skills and Experiences.</h2>
      <div className="skills-container">
        <motion.div className="skills-list">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="skill flex"
              key={skill.name}
            >
              <div className="app__flex">
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="paragraph">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="experiences">
        <motion.div className="experience">
            <div className="experience-year">
              <p className="titlebold">2020</p>
            </div>
            <motion.div className="experience-info">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="experience-content"
                >
                  <h4 className="titlebold">Full Stack Developer Consultant </h4>
                  <p className="titlebold">SALT - Applied Technology (MAY 2022 - JAN 2023)</p>
                  <br />
                  <p className="paragraph">
                  Collaborated with the wider development team to build user-focused
                  websites and applications.Built applications from designing and planning stage to production.
                  Stayed up-to-date with the latest industry trends and technologies.
                  Expanded my TDD experience writing UI and Unit tests.
                  Conducted thorough UX/UI and application testing to ensure high-quality results and a seamless user experience.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>
          <motion.div className="experience">
            <div className="experience-year">
              <p className="titlebold">2017</p>
            </div>
            <motion.div className="experience-info">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="experience-content"
                >
                  <h4 className="titlebold">Web Developer (5years)/ Full Stack Developer (3years) </h4>
                  <p className="titlebold">JD Soft (AUG 2018 - Present)</p>
                  <br />
                  <p className="paragraph">
                  Created web and native applications from planning and design to
                  coding ,testing and launching to production.
                  Made sure that applications stayed up-to-date with the new technologies vand latest updates.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>
          <motion.div className="experience">
            <div className="experience-year">
              <p className="titlebold">2022</p>
            </div>
            <motion.div className="experience-info">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="experience-content"
                >
                  <h4 className="titlebold">Sound Engineer/ Music Artist</h4>
                  <p className="titlebold">Radio As FM (JUL 2004 - OCT 2016)</p>
                  <br />
                  <p className="paragraph">
                  Throughout my diverse and accomplished career, I have skillfully produced music and advertising 
                  materials that resonate with audiences. I have hosted numerous radio shows, with some continuing 
                  to entertain listeners even after 15 years on air. I have successfully led various artist projects,
                  garnering support and collaboration from prominent names in the music industry. 
                  My experience extends to organizing large-scale events, as well as working as a music artist and DJ,
                  showcasing my versatility and passion for the creative arts.
                  </p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Skills, "skillsExperiences"), "skills");